import React from "react";
import styled from "styled-components";
import {
  Button,
  Dropdown,
  Text,
  TextInput,
  variants,
} from "@headstorm/foundry-react-ui";

import HeadingText from "../components/typography/HeadingText";
import BodyText from "../components/typography/BodyText";
import HandwritingText from "../components/typography/HandwritingText";

import Section from "../components/Section";
import WatchVideoButton from "../components/WatchVideoButton";
import Grid from "../components/Grid";
import fonts from "../constants/fonts";
import colors from "../constants/colors";
import { mdiAutoFix } from "@mdi/js";

const ExampleContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
`;

const NotesContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  margin-top: 2rem;
`;

const StyledButtonContainer = styled(Button.Container)`
  ${fonts.button}
  & > div {
    margin-right: 0.75em;
    vertical-align: middle; /* this should be the default in foundry */
  }
`;

const StyledDropdownContainer = styled(Dropdown.Container)`
  align-self: center;
  margin-top: 2rem;
  &:focus {
    outline: 1px solid rgba(255, 255, 255, 0.2);
  }
`;

const StyledValueContainer = styled(Dropdown.ValueContainer)`
  width: 16em;
`;

const StyledOptionItem = styled(Dropdown.OptionItem)`
  color: ${colors.grayDark};
  min-height: 3rem;
  font-weight: bold;
`;

const DatePickerWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Avatar = styled.div`
  &:first-child {
    margin-right: 2rem;
  }
  &:last-child {
    margin-left: 2rem;
  }

  background-size: cover;

  flex: 0 0 4rem;
  width: 4rem;
  height: 4rem;
  border-radius: 2rem;
  background-color: ${colors.grayLight};
`;

const options = [
  {
    id: "tod",
    optionValue: "Today",
  },
  {
    id: "tom",
    optionValue: "Tomorrow",
  },
  {
    id: "yest",
    optionValue: "Yesterday",
  },
  {
    id: "pick",
    optionValue: (
      <DatePickerWrapper
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Text>Specify:&nbsp;&nbsp;</Text>
        <TextInput type="date" />
      </DatePickerWrapper>
    ),
  },
];

export default () => {
  return (
    <Section>
      <Grid>
        <ExampleContainer>
          <NotesContainer>
            <Avatar style={{ backgroundImage: "url(designer.jpg)" }} />
            <HandwritingText>
              Our users want to set a specific start date...
              <br />
              Can you add a date picker to the dropdown for the demo tomorrow?!
            </HandwritingText>
          </NotesContainer>
          <NotesContainer>
            <HandwritingText
              style={{ textAlign: "right", width: "100%", lineHeight: "2" }}
            >
              Easy.
            </HandwritingText>
            <Avatar style={{ backgroundImage: "url(developer.jpg)" }} />
          </NotesContainer>
          <Dropdown
            variant="fill"
            optionsVariant="outline"
            color={colors.accentDark}
            options={options}
            elevation={0.5}
            placeholder="When do you want to start?"
            StyledContainer={StyledDropdownContainer}
            StyledValueContainer={StyledValueContainer}
            StyledOptionItem={StyledOptionItem}
          />
        </ExampleContainer>
        <div>
          <HeadingText
            as="h1"
            internalLinkId="extensibility"
            size="3rem"
            style={{ marginBottom: "1rem" }}
          >
            Scope Creep? No problem.
          </HeadingText>
          <BodyText>
            Requirements continually change during all phases of software
            development. That’s why we developed Foundry UI to be an
            enterprise-grade UI library that understands and embraces change.
            Each and every component can be infinitely customized - whether it’s
            easily configurable global styles, or just one specific component on
            one specific page. Foundry UI supports your freedom to easily design
            and build exactly what you want.
          </BodyText>
          <br />
          <br />
          <Button
            StyledContainer={StyledButtonContainer}
            color={colors.primary}
            elevation={1.1}
            iconPrefix={mdiAutoFix}
          >
            Future-proof your application
          </Button>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <WatchVideoButton
            variant={variants.fill}
            videoTitle="We want you to be in control"
            videoSrc="https://player.vimeo.com/video/447588518"
          />
        </div>
      </Grid>
    </Section>
  );
};
