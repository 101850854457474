import React, { useState } from "react";
import styled from "styled-components";
import { Card, Text } from "@headstorm/foundry-react-ui";
import { mdiChevronDown, mdiChevronUp } from "@mdi/js";
import { marked } from "marked";

import colors from "../constants/colors";

const Container = styled(Card.Container)`
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.1);
  * {
    color: ${colors.background};
  }
`;

export default ({ release, index, isLoading }) => {
  const [isExpanded, setIsExpanded] = useState(index === 0);

  return (
    <Card
      interactionFeedbackProps={{ color: "rgba(255,255,255,0.4)" }}
      elevation={0}
      onClick={() => setIsExpanded(!isExpanded)}
      StyledContainer={Container}
      header={
        <Text
          isLoading={isLoading}
          iconSuffix={isExpanded ? mdiChevronUp : mdiChevronDown}
        >
          {isLoading ? "v0.00.0" : release.tag_name}
        </Text>
      }
    >
      {isLoading && isExpanded ? (
        <Text isLoading>
          orem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
          vehicula, erat pulvinar mollis laoreet, dui nunc facilisis massa, quis
          commodo quam urna id dolor. Suspendisse quis sapien imperdiet,
          facilisis sapien nec, sollicitudin libero. Donec ultrices nisl eget
          ullamcorper dignissim. Integer porttitor, lacus id posuere malesuada,
          dolor lacus ultrices orci, vitae feugiat dui quam quis odio. Curabitur
          pretium ligula sit amet sem egestas, et cursus nulla sodales. Nullam
          eget urna dignissim arcu tempus euismod in et nunc. Fusce vel tortor
          tortor. Praesent eu libero molestie, ullamcorper urna eu, luctus eros.
        </Text>
      ) : (
        isExpanded && (
          <div dangerouslySetInnerHTML={{ __html: marked(release.body) }} />
        )
      )}
    </Card>
  );
};
