import { colors } from "@headstorm/foundry-react-ui";

export default {
  ...colors,
  success: "#01860c",
  grayXDark: "#252c35",
  grayXLight: "#E4E7E8",
  primary: "#E68000",
  primaryDark: "#CB0000",
  accentDark: "#59076D",
  browserSupported: "#EFFB96",
};
