import React from "react";
import styled from "styled-components";

const LogoContainer = styled.div`
  display: flex;
`;

export default ({ className }) => (
  <LogoContainer>
    <svg
      class={className}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 705.47 228.55"
    >
      <path
        class="cls-1"
        d="M569.73,125.06a28.59,28.59,0,0,1-15.6-4.32,22.5,22.5,0,0,1-9.72-10.5q-3.12-6.42-3.11-21V39.34h16V93.51q0,7.06,1.14,9.71,3.36,6.24,10.92,6.24a14.38,14.38,0,0,0,7.44-2,9.16,9.16,0,0,0,4.19-4.68q1.2-2.76,1.2-11.63V39.34h16V89.19q0,12.16-1.56,17.51a22.65,22.65,0,0,1-5.4,9.3,26.6,26.6,0,0,1-9.42,6.72A28.4,28.4,0,0,1,569.73,125.06Z"
      />
      <path class="cls-1" d="M625.75,122.6H610.16V38.8h15.59Z" />
      <path
        class="cls-1"
        d="M658.39,41.66h-6.8v20h-3.12v-20h-6.79V38.72h16.71Zm21.84,2.23-6.49,17.78h-2.23L665,43.59V61.67H661.9v-23h3.88l6.84,18.38,6.84-18.38h3.85v23h-3.08Z"
      />
      <polygon
        class="cls-1"
        points="74.16 37.06 74.16 54.39 39.5 54.39 39.5 71.72 56.83 71.72 56.83 89.05 22.16 89.05 22.16 37.06 74.16 37.06"
      />
      <rect class="cls-1" x="22.16" y="106.39" width="17.33" height="17.33" />
      <path
        class="cls-1"
        d="M116.27,124.14q-16.62,0-27.69-12.85A46.79,46.79,0,0,1,77.5,80.47a47.17,47.17,0,0,1,5.22-22.22,40.1,40.1,0,0,1,14.11-16,35.06,35.06,0,0,1,19.44-5.88q15.54,0,26.94,12.85,11.46,13.09,11.45,31.23,0,18.69-11.07,31.18Q132.35,124.15,116.27,124.14Zm0-15.2q9.09,0,15.7-8.17,6.7-8,6.71-20.3A31.88,31.88,0,0,0,132,60.17q-6.42-8.16-16-8.17t-16,8.17q-6.42,8-6.42,20.3,0,14.06,8.34,22.23A19.87,19.87,0,0,0,116.25,108.94Z"
      />
      <path
        class="cls-1"
        d="M190.08,124.68a28.66,28.66,0,0,1-15.62-4.32,22.62,22.62,0,0,1-9.73-10.52q-3.12-6.42-3.12-21.08V38.85h16V93.09c0,4.72.38,8,1.14,9.73q3.36,6.24,10.93,6.24a14.42,14.42,0,0,0,7.45-2,9.14,9.14,0,0,0,4.2-4.68q1.2-2.78,1.21-11.66V38.85h16V88.76q0,12.19-1.56,17.54a22.6,22.6,0,0,1-5.41,9.31,26.57,26.57,0,0,1-9.43,6.73A28.46,28.46,0,0,1,190.08,124.68Z"
      />
      <path
        class="cls-1"
        d="M297.66,122.22H282.4l-36.28-55v55H230.56V38.31h15.2l35.86,55.44V38.31h16Z"
      />
      <path
        class="cls-1"
        d="M328.77,122.22h-19.1V38.31h19.1q18.14,0,26.91,4.68,8.88,4.51,14.83,14.84Q376,67.51,376,81.61a48.57,48.57,0,0,1-3.12,17.6,34.72,34.72,0,0,1-21.09,21Q345.17,122.22,328.77,122.22Zm4.32-15.62q11.12,0,16-2.34,4.68-2.46,7.81-8.59Q360,89.91,360,81.25q0-27.33-28.89-27.32h-5.46V106.6Z"
      />
      <path
        class="cls-1"
        d="M441.81,122.34H424.69L409.43,93.81H404v28.53H388V38.85H414.9a26.18,26.18,0,0,1,19,7.87,25.91,25.91,0,0,1,7.87,19v.78q0,16.93-16.34,25ZM413.34,78.25a11.23,11.23,0,0,0,8.23-3.6,11.61,11.61,0,0,0,3.48-8.53,10.94,10.94,0,0,0-3.48-8.29,11.35,11.35,0,0,0-8.23-3.36H404V78.25Z"
      />
      <path
        class="cls-1"
        d="M466.7,122.35h-16l18.84-36.29L445,38.85h17.18l3.12,5.83L485.54,84.5ZM485.54,84.5l-15.88,1.39,23.68-47h16.82Z"
      />
      <g class="cls-2">
        <path
          class="cls-1"
          d="M131.44,180.77h-2.65v4.29h-2.34V171.71h5.26c3.11,0,5.07,1.75,5.07,4.48C136.78,179.21,134.38,180.77,131.44,180.77Zm.08-6.92h-2.73v4.8h2.73c1.77,0,2.88-1,2.88-2.4C134.4,174.67,133.27,173.85,131.52,173.85Z"
        />
        <path
          class="cls-1"
          d="M148,185.29a6.91,6.91,0,1,1,7-6.92A6.78,6.78,0,0,1,148,185.29Zm0-11.65a4.51,4.51,0,0,0-4.5,4.73,4.58,4.58,0,0,0,4.54,4.76,4.51,4.51,0,0,0,4.5-4.73A4.57,4.57,0,0,0,148,173.64Z"
        />
        <path
          class="cls-1"
          d="M167.58,171.67h2l3.29,9.94,3.18-9.9h2.46l-4.65,13.45h-2l-3.29-9.6-3.28,9.6h-2l-4.65-13.45h2.54l3.18,9.9Z"
        />
        <path
          class="cls-1"
          d="M193.26,173.81h-7.55v3.47h6.69v2.1h-6.69V183h7.65v2.1h-10V171.71h9.9Z"
        />
        <path
          class="cls-1"
          d="M207.17,185.06l-3.28-4.65H201v4.65h-2.35V171.71h5.95c3.07,0,5,1.62,5,4.23a4,4,0,0,1-3.19,4.07l3.61,5.05Zm-2.8-11.21H201v4.48h3.43c1.68,0,2.75-.88,2.75-2.25S206.1,173.85,204.37,173.85Z"
        />
        <path
          class="cls-1"
          d="M224.77,173.81h-7.56v3.47h6.7v2.1h-6.7V183h7.65v2.1h-10V171.71h9.9Z"
        />
        <path
          class="cls-1"
          d="M235.09,185.06h-5V171.71h5c4.2,0,7.09,2.88,7.09,6.66S239.29,185.06,235.09,185.06Zm0-11.21h-2.63v9.08h2.63a4.54,4.54,0,1,0,0-9.08Z"
        />
        <path
          class="cls-1"
          d="M266.06,175.12a3.19,3.19,0,0,1-1.91,3c1.56.53,2.65,1.43,2.65,3.26,0,2.4-2,3.68-4.94,3.68h-6.18V171.71h6C264.36,171.71,266.06,173,266.06,175.12Zm-2.35.39c0-1.09-.84-1.72-2.35-1.72H258v3.55h3.2C262.7,177.34,263.71,176.74,263.71,175.51Zm.74,5.62c0-1.14-.89-1.81-2.74-1.81H258V183h3.89C263.48,183,264.45,182.35,264.45,181.13Z"
        />
        <path
          class="cls-1"
          d="M277.64,185.06h-2.37V179.8l-5.3-8.09h2.77l3.74,5.91,3.77-5.91h2.69l-5.3,8Z"
        />
      </g>
      <path
        class="cls-3"
        d="M318.53,163.88V174.2H308.7V163.88H301v27.56h7.66V181h9.83v10.47h7.66V163.88Z"
      />
      <path
        class="cls-3"
        d="M363,163.68h7.39l11.76,27.76H374l-2-4.92H361.3l-2,4.92h-8.05Zm6.72,16.9-3.08-7.84-3.12,7.84Z"
      />
      <path
        class="cls-3"
        d="M382.9,163.88h10.66c9.87,0,15.59,5.67,15.59,13.62v.08c0,8-5.8,13.86-15.75,13.86H382.9Zm10.78,20.79c4.58,0,7.62-2.52,7.62-7v-.08c0-4.41-3-7-7.62-7h-3.12v14Z"
      />
      <path
        class="cls-3"
        d="M441.66,170.57h-8.29v-6.69h24.24v6.69h-8.29v20.87h-7.66Z"
      />
      <path
        class="cls-3"
        d="M489.84,163.88h13.07c4.22,0,7.14,1.1,9,3a8.53,8.53,0,0,1,2.44,6.42v.08a8.73,8.73,0,0,1-5.68,8.47l6.59,9.6h-8.84l-5.57-8.34H497.5v8.34h-7.66Zm12.71,13.23c2.61,0,4.11-1.26,4.11-3.27v-.08c0-2.16-1.58-3.26-4.15-3.26h-5v6.61Z"
      />
      <path
        class="cls-3"
        d="M483.12,167.08a14.55,14.55,0,0,0-3.56-2.57l-4.51,6.93,8,3-14.39,15.43,3.81-12.4-8.94-3,4.05-10.55a14.35,14.35,0,0,0-9.2,13.36,14.66,14.66,0,0,0,4,10.48A14,14,0,0,0,473,192.16a14.37,14.37,0,0,0,10.43-4.4,14.64,14.64,0,0,0,4.09-10.4,14.2,14.2,0,0,0-4.38-10.27"
      />
      <path
        class="cls-3"
        d="M409,187.35l4.26-5.08a14.32,14.32,0,0,0,9,3.31c2.05,0,3.16-.71,3.16-1.89v-.08c0-1.14-.91-1.77-4.66-2.64-5.89-1.34-10.43-3-10.43-8.66v-.08c0-5.12,4.07-8.82,10.7-8.82a17.38,17.38,0,0,1,11.37,3.67l-3.83,5.39a13.9,13.9,0,0,0-7.74-2.71c-1.85,0-2.76.78-2.76,1.77v.08c0,1.26.95,1.81,4.78,2.67,6.35,1.38,10.3,3.43,10.3,8.59v.07c0,5.64-4.46,9-11.17,9A19.28,19.28,0,0,1,409,187.35"
      />
      <path
        class="cls-3"
        d="M516.25,163.88h8.1l6.59,10.67,6.59-10.67h8.1v27.56H538V175.61l-7.07,10.79h-.16l-7-10.71v15.75h-7.5Z"
      />
      <path
        class="cls-3"
        d="M329.11,163.88h22.23v6.5H336.69v4.17H350v6H336.69V185h14.85v6.49H329.11Z"
      />
      <path
        class="cls-3"
        d="M553.19,171.92a4,4,0,1,1,.11,0Zm0-7.46a3.51,3.51,0,0,0-3.53,3.55,3.47,3.47,0,0,0,3.51,3.52,3.54,3.54,0,0,0,0-7.07Zm.87,3.93,1.11,1.55h-.94l-1-1.42h-.86v1.42h-.78v-4.11h1.84c1,0,1.59.49,1.59,1.31a1.23,1.23,0,0,1-1,1.25Zm-.66-1.85h-1v1.3h1c.51,0,.81-.25.81-.66s-.3-.65-.81-.65"
      />
    </svg>
  </LogoContainer>
);
