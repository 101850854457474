import React from "react";
import styled from "styled-components";

const LogoContainer = styled.span``;

export default ({
  className,
  viewBoxWidth = 38,
  viewBoxHeight = 35,
  paths,
  pathFills,
  size = "1rem",
}) => (
  <LogoContainer>
    <svg
      className={className}
      viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      width="auto"
    >
      {paths.map((pathEntry, idx) => (
        <path key={idx + ""} d={pathEntry} fill={pathFills[idx]} />
      ))}
    </svg>
  </LogoContainer>
);
