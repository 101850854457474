import React from "react";
import styled from "styled-components";
import { Table } from "@headstorm/foundry-react-ui";

import colors from "../constants/colors";

const StyledTable = styled(Table.Container)`
  margin: 1rem 1rem 0 0;
`;

const StyledHeader = styled(Table.Header)`
  color: ${colors.grayXDark};
`;

const StyledCell = styled(Table.Cell)`
  color: ${colors.grayXDark};
`;

const columns = {
  type: { name: "Area", sortable: true, footer: "Average" },
  percent: { name: "Coverage", sortable: true },
};

export default ({ breakdown }) => {
  const data = [
    {
      type: "Lines",
      percent: `${breakdown.lines.pct}%`,
    },
    {
      type: "Statements",
      percent: `${breakdown.statements.pct}%`,
    },
    {
      type: "Functions",
      percent: `${breakdown.functions.pct}%`,
    },
    {
      type: "Branches",
      percent: `${breakdown.branches.pct}%`,
    },
  ];
  return (
    <Table
      columns={columns}
      data={data}
      StyledContainer={StyledTable}
      StyledHeader={StyledHeader}
      StyledCell={StyledCell}
      minWidthBreakpoint={20}
    />
  );
};
