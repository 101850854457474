import React from "react";
import styled from "styled-components";
// import { a, useSpring } from "react-spring";
import { Text, Button } from "@headstorm/foundry-react-ui";
import {
  mdiCheck,
  mdiClose,
  mdiGoogleChrome,
  mdiFirefox,
  mdiAppleSafari,
  mdiMicrosoftEdge,
  mdiMicrosoftInternetExplorer,
  mdiSourcePull,
  mdiStickerAlert,
  mdiGithub,
  mdiChevronDoubleDown,
} from "@mdi/js";

import HeadingText from "../components/typography/HeadingText";
import BodyText from "../components/typography/BodyText";
import ErrorMessage from "../components/ErrorMessage";
import ErrorBoundary from "../components/ErrorBoundary";

import colors from "../constants/colors";
import fonts from "../constants/fonts";
import CoverageChart from "../components/CoverageChart";
import AccessibilityPlaceholder from "../components/AccessibilityPlaceholder";

const ButtonContainer = styled(Button.Container)`
  ${fonts.button}
  color: ${colors.grayDark} !important;
  flex: 0 0 auto;
`;

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: grid;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  margin-top: 6rem;
  column-gap: 3rem;
  grid-template-columns: 1fr 10fr 1fr;
`;

/* const Panel = styled.div`
  padding: 1rem;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
`;

const TimelineItemContainer = styled.div`
  display: grid;
  align-items: flex-end;
  grid-template-columns: 20% 30% 17% 1fr 1fr;

  margin-bottom: 0.5rem;
`;

const TimelineItem = styled(BodyText)`
  text-align: right;
`;

const BarContainer = styled.div`
  width: 100%;
  height: 1rem;
  background-color: ${colors.grayMedium};
  position: relative;
  overflow: hidden;
  border-radius: 0.25rem;
`;

const Bar = styled(a.div)`
  height: 100%;
  width: 100%;
  background-color: ${colors.primary};
  transform-origin: left center;
`; */

const RepoInfo = styled.div`
  display: grid;
  grid-template-columns: 4fr 4fr 3fr;
  column-gap: 2rem;
`;

const RepoInfoItem = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-between;
`;

const OpenIssueButtonContainer = styled(Button.Container)`
  font-size: 3.5rem;
  padding: 0.25em 0.5em;
  align-items: baseline;
  justify-content: space-between;
  text-decoration: none;

  color: inherit !important;
`;

const ButtonIcon = styled(Text.IconContainer)`
  margin-right: 0.25em;

  svg {
    width: 3rem !important;
    height: 3rem !important;
  }
`;

const ChevronIcon = styled(Button.RightIconContainer)`
  svg {
    width: 2rem !important;
    height: 2rem !important;
  }
`;

const StyledTextContainer = styled(Text.Container)`
  font-weight: bold;
`;

export default ({
  onScreen,
  coverage,
  openIssues,
  pullRequests,
  fetchingIssues,
}) => {
  /* 
    const props = useSpring({
      transform: onScreen ? "scaleX(0.67)" : "scaleX(0)",
      config: { mass: 5, tension: 400, friction: 75, precision: 0.0001 },
      delay: 150,
    });
  */

  return (
    <Container>
      <div />
      <div>
        <HeadingText
          as="h1"
          internalLinkId="project-status"
          size="3rem"
          style={{ marginBottom: "2rem", marginTop: "2rem" }}
        >
          Project status
        </HeadingText>
        <BodyText style={{ marginBottom: "3rem" }}>
          Transparency is the heart of both open source, and Headstorm. In that
          spirit, we surfaced our progress towards achieving our dream UI
          library here, straight from Github.
        </BodyText>
        {/* <Panel>
            <TimelineItemContainer>
              <HeadingText as="h2" size="2rem">
                Components
              </HeadingText>
              <Text
                StyledContainer={TimelineItem}
                color={colors.primary}
                iconPrefix={mdiCheck}
              >
                13 Other base components
              </Text>
              <Text
                StyledContainer={TimelineItem}
                color={colors.primary}
                iconPrefix={mdiCheck}
              >
                Dropdown
              </Text>
              <TimelineItem>Tag</TimelineItem>
              <TimelineItem>Date picker</TimelineItem>
            </TimelineItemContainer>
            <BarContainer>
              <Bar style={props} />
            </BarContainer>
          </Panel> */}
        <RepoInfo>
          <RepoInfoItem>
            Test coverage
            <ErrorBoundary fallbackUI={<ErrorMessage />}>
              <CoverageChart breakdown={coverage} />
            </ErrorBoundary>
          </RepoInfoItem>
          {false && (
            <RepoInfoItem>
              Accessibility
              <AccessibilityPlaceholder />
            </RepoInfoItem>
          )}
          <RepoInfoItem>
            <div>What we're working on</div>
            <Button
              isProcessing={fetchingIssues}
              color="rgba(0,0,0,0.2)"
              StyledContainer={OpenIssueButtonContainer}
              iconSuffix={mdiChevronDoubleDown}
              StyledRightIconContainer={ChevronIcon}
              containerProps={{
                as: "a",
                href: "#contribute",
              }}
            >
              <div>
                <Text
                  StyledContainer={StyledTextContainer}
                  size="3.5rem"
                  iconPrefix={mdiStickerAlert}
                  StyledIconContainer={ButtonIcon}
                >
                  {openIssues.length}
                </Text>
                <Text StyledContainer={StyledTextContainer}>
                  {" "}
                  open issue{openIssues.length !== 1 && "s"}
                </Text>
              </div>
            </Button>
            <Button
              isProcessing={fetchingIssues}
              color="rgba(0,0,0,0.2)"
              StyledContainer={OpenIssueButtonContainer}
              iconSuffix={mdiChevronDoubleDown}
              StyledRightIconContainer={ChevronIcon}
              containerProps={{
                as: "a",
                href: "#contribute",
              }}
            >
              <div>
                <Text
                  StyledContainer={StyledTextContainer}
                  size="3.5rem"
                  iconPrefix={mdiSourcePull}
                  StyledIconContainer={ButtonIcon}
                >
                  {pullRequests.length}
                </Text>
                <Text StyledContainer={StyledTextContainer}>
                  {" "}
                  open PR{pullRequests.length !== 1 && "s"}
                </Text>
              </div>
            </Button>

            <Button
              StyledContainer={ButtonContainer}
              color={colors.primary}
              iconPrefix={mdiGithub}
              elevation={1.1}
              containerProps={{
                as: "a",
                href: "https://github.com/Headstorm/foundry-ui",
              }}
            >
              See them all on GitHub
            </Button>
          </RepoInfoItem>
          <RepoInfoItem>
            Browser support
            <Text
              size="2rem"
              iconPrefix={mdiGoogleChrome}
              iconSuffix={mdiCheck}
              color={colors.background}
            >
              Chrome
            </Text>
            <Text
              size="2rem"
              iconPrefix={mdiFirefox}
              iconSuffix={mdiCheck}
              color={colors.background}
            >
              Firefox
            </Text>
            <Text
              size="2rem"
              iconPrefix={mdiAppleSafari}
              iconSuffix={mdiCheck}
              color={colors.background}
            >
              Safari
            </Text>
            <Text
              size="2rem"
              iconPrefix={mdiMicrosoftEdge}
              iconSuffix={mdiCheck}
              color={colors.background}
            >
              MS Edge
            </Text>
            <Text
              size="2rem"
              iconPrefix={mdiMicrosoftInternetExplorer}
              iconSuffix={mdiClose}
              color={colors.grayLight}
            >
              MS IE
            </Text>
          </RepoInfoItem>
        </RepoInfo>
      </div>
      <div />
    </Container>
  );
};
