import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button } from "@headstorm/foundry-react-ui";
import { mdiChevronDown, mdiChevronUp } from "@mdi/js";

import HeadingText from "../components/typography/HeadingText";
import ErrorMessage from "../components/ErrorMessage";
import ChangelogRelease from "../components/ChangelogRelease";
import ErrorBoundary from "../components/ErrorBoundary";

import { authToken } from "../App";

const LogList = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;

  width: min(80vw, 80rem);
  margin: 0 auto 3rem;
`;

const ShowMoreButtonContainer = styled(Button.Container)`
  border: 1px solid rgba(255, 255, 255, 0.2);
  margin: 0 auto;
  display: flex;
  justify-content: center;
  width: min(80vw, 80rem);
`;
const showMoreSkeletonProps = { style: { display: "block" } };

export default () => {
  const [releases, setReleases] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [numReleasesShown, setNumReleasesShown] = useState(5);

  useEffect(() => {
    // get github changelog
    fetch("https://api.github.com/repos/headstorm/foundry-ui/releases", {
      method: "GET",
      Authorization: `token ${authToken}`,
    })
      .then((res) => res.json())
      .then((releases) => {
        console.log(releases);
        setReleases(releases);
        setIsFetching(false);
      });
  }, []);

  return (
    <LogList>
      <HeadingText
        as="h1"
        size="4rem"
        internalLinkId="releases"
        style={{
          margin: "0 auto 4rem",
          width: "min(80vw, 80rem)",
          textAlign: "left",
        }}
      >
        Changelog
      </HeadingText>
      <ErrorBoundary fallbackUI={<ErrorMessage />}>
        {isFetching ? (
          <>
            <ChangelogRelease isLoading index={0} />
            <ChangelogRelease isLoading index={1} />
            <ChangelogRelease isLoading index={2} />
            <ChangelogRelease isLoading index={3} />
            <ChangelogRelease isLoading index={4} />
          </>
        ) : (
          releases
            .slice(0, numReleasesShown)
            .map((release, i) => (
              <ChangelogRelease key={release.id} index={i} release={release} />
            ))
        )}
      </ErrorBoundary>
      <Button
        StyledContainer={ShowMoreButtonContainer}
        skeletonProps={showMoreSkeletonProps}
        iconSuffix={
          releases.length > numReleasesShown ? mdiChevronDown : mdiChevronUp
        }
        elevation={-1}
        size="1.25rem"
        color="rgba(0,0,0,0.5)"
        onClick={() =>
          setNumReleasesShown(
            releases.length > numReleasesShown ? releases.length : 5
          )
        }
      >
        {releases.length > numReleasesShown
          ? `Show ${releases.length - numReleasesShown} More`
          : "Show less"}
      </Button>
    </LogList>
  );
};
