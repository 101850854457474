import React from "react";
import Icon from "./Icon";

export default ({ size = "1rem" }) => (
  <Icon
    size={"24px"}
    viewBoxWidth={38}
    viewBoxHeight={35}
    paths={[
      "M12.95 0V9.28571C20.4055 13.8729 11.914 18.5157 7.4 20.4286V26H35.15V20.4286C23.2915 15.3586 28.6195 7.42857 37 5.57143V0H12.95ZM0 1.85714C1.4985 5.81286 4.477 8.35714 9.25 9.28571V1.85714H0Z",
    ]}
    pathFills={["#E9D71E"]}
  />
);
