import React from "react";
import Icon from "./Icon";

export default ({ size = "1rem" }) => (
  <Icon
    size={"24px"}
    viewBoxWidth={38}
    viewBoxHeight={35}
    paths={[
      "M36.9609 35.8064C36.2682 36.6021 35.4271 37 34.4375 37C33.4479 37 32.6068 36.6021 31.9141 35.8064C31.2214 35.0108 30.875 34.0583 30.875 32.9492C30.875 31.84 31.2214 30.8876 31.9141 30.0919L33.25 28.2473V21.1222C33.25 21.7491 33.0397 22.2675 32.6191 22.6774L19.1855 35.8064C18.3444 36.6021 17.3424 37 16.1797 37C15.0169 37 14.015 36.6021 13.1738 35.8064L1.22461 24.1603C0.408203 23.3405 0 22.364 0 21.2307C0 20.0974 0.408203 19.1209 1.22461 18.3011L11.875 7.95699V11.5738C11.875 12.8517 12.3389 13.9428 13.2666 14.847C14.1943 15.7512 15.3138 16.2033 16.625 16.2033C17.9362 16.2033 19.0557 15.7512 19.9834 14.847C20.9111 13.9428 21.375 12.8517 21.375 11.5738V8.82502L31.3203 18.5181H33.25C33.918 18.5181 34.4808 18.7411 34.9385 19.1872C35.3962 19.6333 35.625 20.1818 35.625 20.8328V28.2473L36.9609 30.0919C37.6536 30.8876 38 31.84 38 32.9492C38 34.0583 37.6536 35.0108 36.9609 35.8064ZM16.6436 13.8886C15.988 13.8886 15.4251 13.6595 14.9551 13.2014C14.485 12.7432 14.25 12.2007 14.25 11.5738V2.31476C14.25 1.66373 14.485 1.11518 14.9551 0.66911C15.4251 0.223037 15.988 0 16.6436 0C17.2992 0 17.8558 0.223037 18.3135 0.66911C18.7712 1.11518 19 1.66373 19 2.31476V11.5738C19 12.2007 18.7712 12.7432 18.3135 13.2014C17.8558 13.6595 17.2992 13.8886 16.6436 13.8886Z",
    ]}
    pathFills={["#ABCEFF"]}
  />
);
