import React, { useState } from "react";
import styled from "styled-components";
import { animated, useSpring } from "react-spring";
import { Button, Table, variants } from "@headstorm/foundry-react-ui";
import { mdiCellphone, mdiTablet, mdiMonitor } from "@mdi/js";

import HeadingText from "../components/typography/HeadingText";
import BodyText from "../components/typography/BodyText";

// import WatchVideoButton from "../components/WatchVideoButton";
import Section from "../components/Section";
import Grid from "../components/Grid";
import fonts from "../constants/fonts";
import colors from "../constants/colors";

const ButtonContainer = styled(Button.Container)`
  ${fonts.button}
`;

const TableContainer = styled(Table.Container)`
  width: 100%;
`;

const TableHeader = styled(Table.Header)`
  background-color: ${colors.primary};
  color: ${colors.grayDark};
`;
const HeaderCell = styled(Table.HeaderCell)`
  font-weight: 700;
`;

const columns = {
  name: {
    name: "Name",
    sortable: true,
    width: "7rem",
  },
  description: {
    name: "Description",
    sortable: true,
  },
};

const data = [
  {
    name: "Button",
    description: "Run-of-the-mill button with custom interaction options",
  },
  {
    name: "Dropdown",
    description: "Selectable list of options",
  },
  {
    name: "Table",
    description: "Flexible grid of data which can be grouped and sorted",
  },
];

const StyledCell = styled(Table.Cell)`
  color: ${colors.grayXDark};
`;
const FlexyContainer = styled(animated.div)`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  max-height: 574px;
`;
const ControlsContainer = styled.div`
  margin-top: 1rem;
  width: 21rem;
`;

const devicePresets = {
  phone: "50%",
  tablet: "75%",
  desktop: "100%",
};

export default () => {
  const [preset, setPreset] = useState("desktop");
  const animatedStyles = useSpring({
    width: devicePresets[preset],
  });
  return (
    <Section>
      <Grid alignItems="flex-end">
        <FlexyContainer style={animatedStyles}>
          <Table
            minWidthBreakpoint={400}
            StyledContainer={TableContainer}
            StyledHeader={TableHeader}
            StyledHeaderCell={HeaderCell}
            data={data}
            columns={columns}
            StyledCell={StyledCell}
          />
          <ControlsContainer>
            <Button
              iconPrefix={mdiCellphone}
              variant={variants.text}
              color={colors.background}
              onClick={() => setPreset("phone")}
            >
              Small
            </Button>
            <Button
              iconPrefix={mdiTablet}
              variant={variants.text}
              color={colors.background}
              onClick={() => setPreset("tablet")}
            >
              Medium
            </Button>
            <Button
              iconPrefix={mdiMonitor}
              variant={variants.text}
              color={colors.background}
              onClick={() => setPreset("desktop")}
            >
              Large
            </Button>
          </ControlsContainer>
        </FlexyContainer>
        <div>
          <HeadingText
            as="h1"
            internalLinkId="accessibility"
            size="3rem"
            style={{ marginBottom: "1rem" }}
          >
            Built for <span style={{ color: colors.primary }}>all</span> of your
            users
          </HeadingText>
          <BodyText>
            <ul>
              <li>
                Components understand and respond to the space they're given in
                your app instead of guessing based on the size of the window
              </li>
              <li>
                Empower your users to complete their tasks — regardless of the
                device they're using or their own disabilities
              </li>
              <li>
                With{" "}
                <a href="https://www.a11yproject.com/">a11y accessibility</a>{" "}
                testing built into its deployment pipeline, Foundry UI makes it
                difficult to be inaccessible
              </li>
            </ul>
          </BodyText>
          <br />
          <br />
          <Button
            StyledContainer={ButtonContainer}
            color={colors.primary}
            elevation={1.1}
          >
            Reach more users
          </Button>
          {/* <WatchVideoButton
            videoSrc="https://www.html5rocks.com/en/tutorials/video/basics/devstories.webm"
            variant={variants.fill}
            videoTitle=""
            /> */}
        </div>
      </Grid>
    </Section>
  );
};
