import React from "react";
import styled from "styled-components";
import { Button, variants } from "@headstorm/foundry-react-ui";
import { darken } from "polished";

import HeadingText from "../components/typography/HeadingText";
import fonts from "../constants/fonts";
import colors from "../constants/colors";
import Headstorm from "../components/Headstorm";

const Container = styled.div`
  width: 100%;
  height: 50vh;
`;

const ButtonContainer = styled(Button.Container)`
  ${fonts.button}
  margin-left: .5rem;
  margin-right: 0.5rem;
  color: ${colors.grayDark} !important;
`;

const Panel = styled.div`
  padding: 1rem 1rem 2.5rem;
  border-radius: 0.5rem;
  background-color: ${darken(0.05, colors.grayXDark)};
  margin-bottom: 5rem;
  max-height: 280px;
  text-align: center;
`;

const PoweredBy = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;

export default () => {
  return (
    <Container>
      <Panel>
        <HeadingText
          as="h1"
          size="3rem"
          style={{ marginBottom: "2rem", marginTop: "2rem" }}
        >
          Strike now while the iron is hot
        </HeadingText>
        <Button
          variant={variants.fill}
          color={colors.primary}
          StyledContainer={ButtonContainer}
          containerProps={{
            as: "a",
            href: "mailto: support@foundry-ui.com",
          }}
        >
          Contact us
        </Button>
      </Panel>
      <PoweredBy>
        <div>Powered by</div>
        <br />
        <div>
          <Headstorm width={"350px"} />
        </div>
      </PoweredBy>
    </Container>
  );
};
