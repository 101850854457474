import React from "react";
import { colors, variants } from "@headstorm/foundry-react-ui";
import Icon from "@mdi/react";
import { mdiChevronDoubleDown } from "@mdi/js";

import Grid from "../components/Grid";
import Section from "../components/Section";
import HeadingText from "../components/typography/HeadingText";
import BodyText from "../components/typography/BodyText";
import { videoThumbnail } from "../constants/thumbnails";
import WatchVideoCard from "../components/WatchVideoCard";

export default ({ currSection }) => (
  <Section>
    <BodyText style={{ marginBottom: "0.25rem" }}>
      The only open-source UI library
    </BodyText>
    <HeadingText as="h1" size="5rem" style={{ marginBottom: "2rem" }}>
      Made to be <span style={{ color: colors.primary }}>remade</span>
    </HeadingText>
    <Grid>
      <div>
        <h2>We created Foundry UI to</h2>
        <BodyText>
          <ul>
            <li>
              Support applications at every stage from proof-of-concept, launch,
              and support
            </li>
            <li>
              Fully customize the styling and behavior of individual components
              with Foundry’s unique architecture
            </li>
            <li>
              Reach users on any device, even if that device is a screen-reader.
            </li>
          </ul>
        </BodyText>
      </div>
      <WatchVideoCard
        videoTitle="Why we created Foundry UI"
        variant={variants.fill}
        videoSrc="https://player.vimeo.com/video/447589067"
        thumbnail={videoThumbnail}
      />
    </Grid>
    <BodyText
      size="1.25rem"
      style={{
        position: "absolute",
        bottom: "0",
        textAlign: "center",
        opacity: 1 / (currSection * 10),
      }}
    >
      Scroll to see why Foundry UI is loved by our developers, designers, and
      product leaders alike
      <br />
      <Icon path={mdiChevronDoubleDown} size="2rem" />
    </BodyText>
  </Section>
);
