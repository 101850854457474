import React, { useState } from "react";
import styled from "styled-components";
import { mdiRefresh } from "@mdi/js";
import {
  TextInput,
  Button,
  Card,
  Checkbox,
  Dropdown,
  Modal,
  Label,
  variants,
  RangeSlider,
} from "@headstorm/foundry-react-ui";
import colors from "../constants/colors";

// All 50 + DC
const stateAbbreviations = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "DC",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];

const defaultState = {
  firstName: "John",
  lastName: "Doe",
  title: "Organizer",
  city: "123 Easy Street",
  state: "WA",
  age: 30,
  email: "john.doe@email.com",
  company: "Super Awesome Co",
  department: "Event Planning",
  notifications: false,
};

const StyledLabel = styled(Label.Label)`
  font-weight: 700;
  font-size: 1.125rem;
`;

/* Both of these should be fixed in Foundry UI */
const StyledInput = styled(TextInput.Input)`
  height: unset;
`;
const StyledInputContainer = styled(TextInput.Container)`
  border-width: 1px;
`;

const StyledHeader = styled(Card.Header)`
  font-family: "Bebas Neue";
  font-size: 2rem;
`;

// Adjusting the style of the footer to help position the buttons added
const StyledFooter = styled(Card.Footer)`
  display: flex;
  justify-content: space-between;
`;

const StyledBody = styled(Card.Body)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
  row-gap: 1.5rem;
`;

const ResetButtonContainer = styled(Button.Container)`
  margin-right: 1.5rem;
`;

export default () => {
  const [state, setState] = useState(defaultState);
  const [val, setVal] = useState(0);
  const [isSaving, setIsSaving] = useState(false);
  const [isResetting, setIsResetting] = useState(false);
  const [savedState, setSavedState] = useState(defaultState);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // By creating a callback function like this, we will create a new callback for each
  // handler on every render, which is not the ideal scenario for maximum performance.
  // To prevent this, use the useCallback helper. We're  doing this to shorten the length
  // of the example's source code.
  const createTextInputCallback = (property) => {
    return (event) => {
      setState({ ...state, [property]: event.target.value });
    };
  };

  const onSave = () => {
    const newSavedState = { ...state };
    setIsSaving(true);

    // Use a setTimeout to simulate a network call
    setTimeout(() => {
      setSavedState(newSavedState);
      setIsSaving(false);
    }, Math.random() * 1000);
  };

  const onReset = () => {
    setIsResetting(true);
    setIsModalOpen(false);

    // Simulate network call
    setTimeout(() => {
      setIsResetting(false);
      setState({ ...savedState });
      setVal(0);
    }, Math.random() * 1000 + 500);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const saveButton = (
    <Button
      key="saveButton"
      onClick={onSave}
      color={colors.primaryDark}
      isProcessing={isSaving}
      type={variants.submit}
      disabled={
        !(
          typeof state.firstName !== "undefined" &&
          state.firstName.length > 0 &&
          !!state.age &&
          +state.age > 13
        )
      }
    >
      {isSaving ? "Saving" : "Save"}
    </Button>
  );

  const cancelButton = (
    <Button
      key="cancelButton"
      onClick={openModal}
      color={colors.destructive}
      isProcessing={isResetting}
      variant={variants.outline}
    >
      Reset
    </Button>
  );

  const confirmButton = (
    <Button
      key="confirmButton"
      onClick={onReset}
      color={colors.destructive}
      iconPrefix={mdiRefresh}
      type={variants.reset}
    >
      Reset form
    </Button>
  );

  const abortButton = (
    <Button
      key="cancelButton"
      onClick={closeModal}
      color={colors.destructive}
      variant={variants.text}
      StyledContainer={ResetButtonContainer}
      type={variants.button}
    >
      Go back
    </Button>
  );

  const Header = "Forms made easy";

  return (
    <>
      <Card
        elevation={1}
        header={Header}
        footer={[cancelButton, saveButton]}
        StyledFooter={StyledFooter}
        StyledBody={StyledBody}
        StyledHeader={StyledHeader}
      >
        <Label
          iconContainerProps={{ size: "2rem" }}
          StyledLabel={StyledLabel}
          labelText="First Name"
          htmlFor="firstName"
          isValid={state.firstName !== ""}
          isRequired
          key="firstName"
        >
          <TextInput
            StyledInput={StyledInput}
            StyledContainer={StyledInputContainer}
            onChange={createTextInputCallback("firstName")}
            value={state.firstName}
            isValid={
              typeof state.firstName !== "undefined" &&
              state.firstName.length > 0
            }
            errorMessage="First Name cannot be blank"
            id="firstName"
          />
        </Label>

        <Label
          StyledLabel={StyledLabel}
          labelText="Last Name"
          htmlFor="lastName"
          key="lastName"
        >
          <TextInput
            StyledInput={StyledInput}
            StyledContainer={StyledInputContainer}
            onChange={createTextInputCallback("lastName")}
            value={state.lastName}
            id="lastName"
          />
        </Label>

        <Label
          StyledLabel={StyledLabel}
          labelText="Age"
          htmlFor="age"
          isRequired
          isValid={!!state.age && +state.age > 13}
          key="age"
        >
          <TextInput
            StyledInput={StyledInput}
            StyledContainer={StyledInputContainer}
            onChange={createTextInputCallback("age")}
            value={`${state.age}`}
            errorMessage="Must be 13+"
            isValid={!!state.age && +state.age > 13}
            id="age"
            type="number"
          />
        </Label>

        <Label
          StyledLabel={StyledLabel}
          labelText="State"
          htmlFor="state"
          key="state"
        >
          <Dropdown
            name="state"
            options={stateAbbreviations.map((abr) => ({
              id: abr,
              optionValue: abr,
            }))}
            color="#51575d"
            values={[state.state]}
            onSelect={(val) => {
              setState({ ...state, state: val });
            }}
          />
        </Label>

        <Label
          StyledLabel={StyledLabel}
          labelText="Notifications"
          htmlFor="notifications"
          key="notifications"
        >
          <Checkbox
            onClick={() => {
              setState({ ...state, notifications: !state.notifications });
            }}
            checked={state.notifications}
            checkboxType={Checkbox.Types.check}
          >
            Enabled
          </Checkbox>
        </Label>
        <Label StyledLabel={StyledLabel} labelText="Interest level">
          <RangeSlider
            showSelectedRange
            springOnRelease
            min={0}
            max={10}
            debounceInterval={8}
            onDrag={(newVal) => {
              setVal(Math.round(newVal));
            }}
            values={[
              {
                value: val,
                label: val,
              },
            ]}
          />
        </Label>
      </Card>
      {isModalOpen && (
        <Modal
          onClose={closeModal}
          onClickOutside={closeModal}
          backgroundDarkness={0.5}
        >
          <Card
            elevation={1}
            header="Would you like to continue?"
            footer={[abortButton, confirmButton]}
          >
            You will lose any unsaved changes, are you sure you would like to
            reset?
          </Card>
        </Modal>
      )}
    </>
  );
};
