import React from "react";
import styled from "styled-components";
import { mdiTextBox, mdiCheckBold, mdiArrowDownBold } from "@mdi/js";
import Icon from "@mdi/react";
import { Card, Button, Text, variants } from "@headstorm/foundry-react-ui";

import colors from "../constants/colors";
import fonts from "../constants/fonts";

import Section from "../components/Section";
import WatchVideoButton from "../components/WatchVideoButton";
import HeadingText from "../components/typography/HeadingText";
import BodyText from "../components/typography/BodyText";
import Grid from "../components/Grid";
import Hammer from "../components/icons/Hammer";
import Anvil from "../components/icons/Anvil";
import PaintCan from "../components/icons/PaintCan";

const StyledButtonContainer = styled(Button.Container)`
  ${fonts.button}
  align-items: center;
  color: ${colors.grayDark} !important;

  & > div {
    margin-right: 0.75em;
  }
`;

const StyledHeader = styled(Card.Header)`
  ${fonts.heading}
  font-size: 2rem;
  color: ${colors.accentDark};
  padding-bottom: 1rem;
`;

const StyledBody = styled(Card.Body)`
  padding-top: 1rem;
`;

const StyledFooter = styled(Card.Footer)`
  position: relative;
`;

const RestyledHeader = styled(StyledHeader)`
  ${fonts.heading}
  font-size: 2rem;
  color: ${colors.background};
  background-color: ${colors.accentDark};
`;

const RestyledButtonContainer = styled(Button.Container)`
  position: absolute;
  bottom: 0;
  transform: translateY(50%);

  overflow: hidden;
  border-radius: 50%;
  height: 4rem;
  width: 4rem;
  align-items: center;
  justify-content: center;
  padding: 0;
`;

const CardList = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
`;

const StyledTextContainer = styled(Text.Container)`
  font-weight: 200;
`;

const IconList = styled.div`
  display: grid;
  column-gap: 1rem;
  row-gap: 1rem;
  grid-template-columns: 1.5rem auto;
`;

const TextContent = styled.div`
  align-self: start;
`;

export default () => (
  <Section>
    <Grid side="left">
      <TextContent>
        <HeadingText
          as="h1"
          size="3rem"
          internalLinkId="theming"
          style={{ marginBottom: "2rem", textAlign: "left" }}
        >
          This brand is{" "}
          <span style={{ color: colors.primary }}>your brand</span>,
          <br />
          this brand is my brand
        </HeadingText>
        <BodyText>
          Frustrated that your UI library can't deliver on your designer’s
          beautiful creations?
        </BodyText>
        <br />
        <br />
        <IconList>
          <Hammer size="2.5rem" />
          <Text StyledContainer={StyledTextContainer} size="1.25rem">
            Create branded and professional proof-of-concepts, and refine them
            into enterprise-level UIs
          </Text>
          <PaintCan size="2.5rem" />
          <Text StyledContainer={StyledTextContainer} size="1.25rem">
            Globally theme colors, fonts, animations, and more with a simple and
            extendable theme provider
          </Text>
          <Anvil size="2.5rem" />
          <Text StyledContainer={StyledTextContainer} size="1.25rem">
            Tweak or replace every components' "subcomponents" for infinite*
            customizability
            <br />
            <br />* Really, we mean infinite
          </Text>
        </IconList>
        <br />
        <br />
        <Button
          StyledContainer={StyledButtonContainer}
          iconPrefix={mdiTextBox}
          color={colors.primary}
          elevation={1.2}
          containerProps={{
            as: "a",
            href: "https://headstorm.github.io/foundry-ui/",
          }}
        >
          Start branding
        </Button>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <WatchVideoButton
          variant={variants.fill}
          videoTitle="We want you to be in control"
          videoSrc="https://player.vimeo.com/video/447587727"
        />
      </TextContent>
      <CardList>
        <Card
          header="Build components quickly"
          footer={
            <Button color={colors.grayXDark} variant={variants.text}>
              I agree
            </Button>
          }
          StyledHeader={StyledHeader}
          StyledBody={StyledBody}
        >
          Whether you're building a proof-of-concept UI or iterating quickly for
          a hackathon, you need beautiful components with minimal styling to get
          your frontend stood up quickly.
        </Card>
        <br />
        <Icon path={mdiArrowDownBold} size="3rem" />
        <br />
        <Card
          header="Easily restyle them later"
          footer={
            <Button
              StyledContainer={RestyledButtonContainer}
              iconPrefix={mdiCheckBold}
              color={colors.primary}
              elevation={2}
            ></Button>
          }
          StyledHeader={RestyledHeader}
          StyledBody={StyledBody}
          StyledFooter={StyledFooter}
        >
          Once a designer starts working on the project, it shouldn't require a
          painful rewrite, package switch, or slugging it out with a stubborn UI
          library to match your designs.
        </Card>
      </CardList>
    </Grid>
  </Section>
);
