import React from "react";
import styled from "styled-components";
import HeadingText from "./typography/HeadingText";
import colors from "../constants/colors";

// need a nicer hover effect when it's already active - maybe react spring
const Container = styled.a`
  ${({ active }) => `
    cursor: pointer;
    transition: opacity 0.2s;
    padding:.5rem;
    text-align: center;
    white-space: nowrap;

    ${
      !active
        ? `
      &:not(:hover) {
        opacity: .8;
      }
    `
        : ""
    }

    &:hover {
      opacity: 1;
    }
  `}
`;

// colors should activate on hover, not just when active

export default ({ name, description, active = false, link }) => (
  <Container href={link} active={active}>
    <HeadingText size="1.5rem" color={colors.background}>
      {name}
    </HeadingText>
  </Container>
);
