import React from "react";
import styled from "styled-components";
import colors from "../../constants/colors";
import fonts from "../../constants/fonts";

const Text = styled.p(
  ({ color, size }) => `
    ${fonts.handwriting}
    margin: 0;
    ${size ? `font-size: ${size};` : ""}
    color: ${color};
  `
);

export default ({
  children,
  as = "p",
  size,
  color = colors.background,
  ...otherProps
}) => (
  <Text as={as} size={size} color={color} {...otherProps}>
    {children}
  </Text>
);
