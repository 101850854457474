import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { Button } from "@headstorm/foundry-react-ui";

import colors from "../constants/colors";
import HeadingText from "../components/typography/HeadingText";
import IssueCard from "../components/IssueCard";
import ErrorMessage from "../components/ErrorMessage";

import { mdiChevronDown, mdiChevronUp } from "@mdi/js";
import ErrorBoundary from "../components/ErrorBoundary";

const CardGrid = styled.div`
  display: grid;
  column-gap: 2rem;
  row-gap: 2rem;
  grid-template-columns: 1fr 1fr 1fr;

  width: min(80vw, 80rem);
  margin: 0 auto 3rem;
`;

const ShowMoreButtonContainer = styled(Button.Container)`
  border: 1px solid rgba(255, 255, 255, 0.2);
  margin: 0 auto;
  display: flex;
  justify-content: center;
  width: min(80vw, 80rem);
`;
const showMoreSkeletonProps = { style: { display: "block" } };

const workableLabels = ["good first issue", "help wanted"];

export default ({ openIssues, pullRequests, fetchingIssues }) => {
  const readyToWork = useMemo(
    () =>
      openIssues.filter((iss) =>
        iss.labels.some((label) => workableLabels.includes(label.name))
      ),
    [openIssues]
  );

  const documentation = useMemo(
    () =>
      openIssues.filter((iss) =>
        iss.labels.some((label) => label.name === "documentation")
      ),
    [openIssues]
  );

  const [readyCount, setReadyCount] = useState(3);
  const [prCount, setPRCount] = useState(3);
  const [docsCount, setDocsCount] = useState(3);

  return (
    <>
      <HeadingText
        as="h1"
        size="4rem"
        internalLinkId="contribute"
        style={{
          margin: "0 auto 4rem",
          width: "min(80vw, 80rem)",
          textAlign: "left",
        }}
      >
        Ready to contribute?
      </HeadingText>
      <HeadingText
        as="h2"
        size="2rem"
        style={{
          margin: "6rem auto 1rem",
          width: "min(80vw, 80rem)",
          textAlign: "left",
        }}
      >
        <span style={{ color: colors.primary }}>
          {readyToWork.length} ticket
          {readyToWork.length !== 1 && "s"}
        </span>{" "}
        {readyToWork.length !== 1 ? "are " : "is "}
        good for newcomers or need help
      </HeadingText>
      <ErrorBoundary fallbackUI={<ErrorMessage />}>
        <CardGrid>
          {readyToWork.slice(0, readyCount).map((iss) => (
            <IssueCard issue={iss} />
          ))}
        </CardGrid>
        {readyToWork.length > 3 && (
          <Button
            StyledContainer={ShowMoreButtonContainer}
            skeletonProps={showMoreSkeletonProps}
            iconSuffix={
              readyToWork.length > readyCount ? mdiChevronDown : mdiChevronUp
            }
            elevation={-4}
            size="1.25rem"
            color="rgba(0,0,0,.2)"
            onClick={() =>
              setReadyCount(
                readyToWork.length > readyCount ? readyToWork.length : 3
              )
            }
          >
            {readyToWork.length > readyCount
              ? `Show ${readyToWork.length - readyCount} More`
              : "Show less"}
          </Button>
        )}
      </ErrorBoundary>

      <HeadingText
        as="h2"
        size="2rem"
        style={{
          margin: "6rem auto 1rem",
          width: "min(80vw, 80rem)",
          textAlign: "left",
        }}
      >
        <span style={{ color: colors.primary }}>
          {pullRequests.length} open pull request
          {pullRequests.length !== 1 && "s"}
        </span>{" "}
        {pullRequests.length !== 1 ? "are " : "is "}
        ready to be reviewed
      </HeadingText>
      <ErrorBoundary fallbackUI={<ErrorMessage />}>
        <CardGrid>
          {pullRequests.slice(0, prCount).map((pr) => (
            <IssueCard issue={pr} />
          ))}
        </CardGrid>
        {pullRequests.length > 3 && (
          <Button
            StyledContainer={ShowMoreButtonContainer}
            skeletonProps={showMoreSkeletonProps}
            iconSuffix={
              pullRequests.length > prCount ? mdiChevronDown : mdiChevronUp
            }
            elevation={-1}
            size="1.25rem"
            color="rgba(0,0,0,0.5)"
            onClick={() =>
              setPRCount(
                pullRequests.length > prCount ? pullRequests.length : 3
              )
            }
          >
            {pullRequests.length > prCount
              ? `Show ${pullRequests.length - prCount} More`
              : "Show less"}
          </Button>
        )}
      </ErrorBoundary>

      <HeadingText
        as="h2"
        size="2rem"
        style={{
          margin: "6rem auto 1rem",
          width: "min(80vw, 80rem)",
          textAlign: "left",
        }}
      >
        <span style={{ color: colors.primary }}>
          {documentation.length} Documentation improvement
          {documentation.length !== 1 && "s"}
        </span>{" "}
        {documentation.length !== 1 ? "are " : "is "}
        ready to be written
      </HeadingText>
      <ErrorBoundary fallbackUI={<ErrorMessage />}>
        <CardGrid>
          {documentation.slice(0, docsCount).map((iss) => (
            <IssueCard issue={iss} />
          ))}
        </CardGrid>
        {documentation.length > 3 && (
          <Button
            StyledContainer={ShowMoreButtonContainer}
            skeletonProps={showMoreSkeletonProps}
            iconSuffix={
              documentation.length > docsCount ? mdiChevronDown : mdiChevronUp
            }
            elevation={-1}
            size="1.25rem"
            color="rgba(0,0,0,0.5)"
            onClick={() =>
              setDocsCount(
                documentation.length > docsCount ? documentation.length : 3
              )
            }
          >
            {documentation.length > docsCount
              ? `Show ${documentation.length - docsCount} More`
              : "Show less"}
          </Button>
        )}
      </ErrorBoundary>
    </>
  );
};
