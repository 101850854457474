import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Text } from "@headstorm/foundry-react-ui";

import NavBar from "./components/NavBar";
// import Example from "./components/Example";
import Intro from "./sections/Intro";
import StylingExample from "./sections/StylingExample";
import Accessability from "./sections/Accessibility";
// import BossCTA from "./sections/BossCTA";
// import Roadmap from "./sections/Roadmap";
import Changelog from "./sections/Changelog";
import ProductStatus from "./sections/ProductStatus";
import ScopeCreep from "./sections/ScopeCreep";
import AppNeeds from "./sections/AppNeeds";
import StrikeNow from "./sections/StrikeNow";
import Contributing from "./sections/Contributing";

const Container = styled.div``;
export const authToken = "7f8640a21254f78b3ac7b720595ce9fa671c7606";

function App() {
  // resize observer maybe
  const windowHeight = window.innerHeight;
  const [scrollPos, setScrollPos] = useState(0);

  const [fetchingIssues, setFetching] = useState(true);
  const [openIssues, setOpenIssues] = useState([]);
  const [pullRequests, setPullRequests] = useState([]);
  const [coverage, setCoverage] = useState({
    lines: { pct: 0 },
    statements: { pct: 0 },
    functions: { pct: 0 },
    branches: { pct: 0 },
  });

  useEffect(() => {
    // get github issues
    fetch("https://api.github.com/repos/headstorm/foundry-ui/issues", {
      method: "GET",
      Authorization: `token ${authToken}`,
      filter: "all",
    })
      .then((res) => res.json())
      .then((allIssues) => {
        console.log(allIssues);
        setOpenIssues(
          allIssues.filter(
            (iss) => !iss.hasOwnProperty("pull_request") && iss.state === "open"
          )
        );
        setPullRequests(
          allIssues.filter(
            (iss) => iss.hasOwnProperty("pull_request") && iss.state === "open"
          )
        );
        setFetching(false);
      });

    fetch(
      "https://raw.githubusercontent.com/headstorm/foundry-ui/master/coverage/coverage-summary.json",
      {
        method: "GET",
      }
    )
      .then((res) => res.json())
      .then((coverageData) => {
        setCoverage(coverageData.total);
      });
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScrollPos(window.scrollY);
    });
    setScrollPos(window.scrollY);
  }, [windowHeight]);

  const currSection = scrollPos / (windowHeight * 0.9);

  return (
    <Container>
      <NavBar />
      <Intro currSection={currSection} />
      <StylingExample />
      <ScopeCreep />
      <Accessability />
      <AppNeeds />
      <ProductStatus
        coverage={coverage}
        onScreen={currSection > 4.3}
        openIssues={openIssues}
        pullRequests={pullRequests}
        fetchingIssues={fetchingIssues}
      />
      {fetchingIssues ? (
        <Text isProcessing></Text>
      ) : (
        <Contributing
          openIssues={openIssues}
          pullRequests={pullRequests}
          fetchingIssues={fetchingIssues}
        />
      )}
      <Changelog />
      <StrikeNow />
      {/* <Example currSection={currSection} /> */}
    </Container>
  );
}

export default App;
