import React from "react";
import styled from "styled-components";
import { Card, Tag, Text } from "@headstorm/foundry-react-ui";
import { mdiForum } from "@mdi/js";

import colors from "../constants/colors";

const StyledCardBody = styled(Card.Body)`
  word-break: break-word;
  padding-top: 0.75rem;
  flex-grow: 1;
`;
const StyledCardHeader = styled(Card.Header)`
  font-size: 1rem;
`;
const StyledCardFooter = styled(Card.Footer)`
  align-items: center;
  justify-content: space-between;
  flex-flow: row nowrap;
`;

const StyledCommentContainer = styled(Text.Container)`
  font-family: Bebas Neue;
  white-space: nowrap;
`;

const TagFlex = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 0.125rem 0.25rem;
  margin-bottom: 1.5rem;
`;

const TagContainer = styled(Tag.Container)`
  padding: 0.5em 0.75em;
  font-size: 0.75rem;
  font-weight: bold;
`;

const IssueBody = styled.div`
  white-space: pre-line;
  font-size: 0.825rem;
`;

const UserGrid = styled.div`
  display: flex;
  flex-direction: row wrap;
  justify-content: space-between;
  flex: 1 1 auto;
  align-items: flex-start;
  gap: 0.5rem;
  margin-right: 2rem;
`;

const AvatarGrid = styled.div`
  display: grid;
  grid-template-areas: "avatar adjective" "avatar name";

  font-size: 0.825rem;
`;

const Adjective = styled.div`
  grid-area: adjective;
`;

const Name = styled.div`
  grid-area: name;
  font-weight: 700;
`;

const Avatar = styled.div`
  grid-area: avatar;
  background-size: cover;

  margin-right: 0.5rem;
  flex: 0 0 2rem;
  width: 2rem;
  height: 2rem;
  border-radius: 1rem;
  background-color: ${colors.grayXLight};
`;

export default ({ issue }) => (
  <Card
    StyledBody={StyledCardBody}
    StyledHeader={StyledCardHeader}
    StyledFooter={StyledCardFooter}
    onClick={() => window.open(issue.html_url)}
    key={issue.id}
    header={issue.title}
    footer={
      <>
        <UserGrid>
          <AvatarGrid>
            <Avatar
              style={{
                backgroundImage: `url(${issue.user.avatar_url})`,
              }}
            />
            <Adjective>Created by</Adjective>
            <Name>{issue.user.login}</Name>
          </AvatarGrid>
          {issue.assignees.length === 0 && (
            <AvatarGrid>
              <Avatar />
              <Adjective>Unassigned</Adjective>
            </AvatarGrid>
          )}
          {issue.assignees.map((assignee) => (
            <AvatarGrid id={assignee.id}>
              <Avatar
                style={{
                  backgroundImage: `url(${assignee.avatar_url})`,
                }}
              />
              {issue.assignees < 3 && (
                <>
                  <Adjective>Assigned to</Adjective>
                  <Name>{assignee.login}</Name>
                </>
              )}
            </AvatarGrid>
          ))}
        </UserGrid>
        <Text
          size="1rem"
          iconPrefix={mdiForum}
          StyledContainer={StyledCommentContainer}
        >
          {issue.comments}
        </Text>
      </>
    }
  >
    <TagFlex>
      {issue.labels
        .filter((label) => !label.name.includes("Story Point"))
        .map((label) => (
          <Tag
            StyledContainer={TagContainer}
            key={label.name}
            color={`#${label.color}`}
          >
            {label.name}
          </Tag>
        ))}
    </TagFlex>
    <IssueBody>
      {issue.body && issue.body.length > 180
        ? `${issue.body.slice(0, 180)}...`
        : issue.body}
    </IssueBody>
  </Card>
);
