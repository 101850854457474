import React from "react";
import styled from "styled-components";

const Grid = styled.div`
  ${({ alignItems = "center" }) => `
    display: grid;
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;

    align-items: ${alignItems};
    column-gap: 8rem;
    grid-template-columns: 1fr 1fr;

    @media (max-width: 1080px) {
      grid-template-columns: 1fr;
      padding: 8vw;
    }
  `}
`;

export default ({ children, alignItems }) => (
  <Grid alignItems={alignItems}>{children}</Grid>
);
