import React, { useState } from "react";
import styled from "styled-components";
import { mdiTelevisionPlay } from "@mdi/js";
import { Button } from "@headstorm/foundry-react-ui";

import VideoPlayerModal from "./VideoPlayerModal";
import colors from "../constants/colors";
import fonts from "../constants/fonts";

const ButtonContainer = styled(Button.Container)`
  ${fonts.button}
  align-items: center;

  & > div {
    margin-right: 0.75em;
    vertical-align: middle; /* this should be the default in Foundry UI */
  }
`;

export default ({
  videoSrc,
  variant = "text",
  color = colors.background,
  videoTitle,
  children,
}) => {
  const [videoVisibility, setVideoVisibility] = useState(false);

  return (
    <>
      <Button
        StyledContainer={ButtonContainer}
        iconPrefix={mdiTelevisionPlay}
        variant={variant}
        color={color}
        elevation={1.1}
        onClick={() => setVideoVisibility(true)}
      >
        {children || "Watch the video"}
      </Button>
      {videoVisibility && (
        <VideoPlayerModal
          title={videoTitle}
          src={videoSrc}
          onClose={() => setVideoVisibility(false)}
        />
      )}
    </>
  );
};
