import React from "react";
import { Card, Button } from "@headstorm/foundry-react-ui";
import styled from "styled-components";

import fonts from "../constants/fonts";
import colors from "../constants/colors";

const ErrorCardContainer = styled(Card.Container)`
  margin: 1rem auto;
  display: block;
  width: fit-content;
`;

const ReportErrorButtonContainer = styled(Button.Container)`
  ${fonts.button}
  color: ${colors.background} !important;
  flex: 0 0 auto;
`;

export default () => (
  <Card
    StyledContainer={ErrorCardContainer}
    header="Woah!"
    footer={
      <Button
        StyledContainer={ReportErrorButtonContainer}
        color={colors.destructive}
        containerProps={{ as: "a", href: "mailto:foundryui@headstorm.com" }}
      >
        Report it to our team!
      </Button>
    }
  >
    Something in this section isn't working, potentially due to an issue with
    GitHub...
    <br />
    Good thing we used Foundry UI's ErrorBoundary here.
  </Card>
);
