import React from "react";
import styled from "styled-components";
import colors from "../../constants/colors";
import fonts from "../../constants/fonts";

import { mdiLink } from "@mdi/js";

const Text = styled.h2(
  ({ id, color, size }) => `
    ${fonts.heading}
    margin: 0;
    ${size ? `font-size: ${size};` : ""}
    color: ${color || "initial"};
    line-height: 1;
    display: block;

    scroll-margin-top: 9rem;

    ${
      id
        ? `
          text-decoration: none;
          
          &:link {
            color: ${color || "initial"};
          }

          svg { opacity: 0; transition: opacity .4s; }

          &:hover{
            text-decoration: underline;

            svg {
              opacity: 1;
            }
          }
        `
        : ""
    }
  `
);

export default ({
  children,
  as = "h2",
  size,
  color = colors.background,
  internalLinkId,
  ...otherProps
}) => (
  <Text
    as={internalLinkId ? "a" : as}
    href={`#${internalLinkId}`}
    iconSuffix={internalLinkId ? mdiLink : undefined}
    id={internalLinkId}
    size={size}
    color={color}
    {...otherProps}
  >
    {children}
  </Text>
);
