import React from "react";
import styled from "styled-components";
import { Modal } from "@headstorm/foundry-react-ui";
import colors from "../constants/colors";

// const StyledVideo = styled.video`
//   width: 100%;
//   height: 50%;
//   border-radius: 0.5rem;
//   box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.7);
// `;

const StyledVimeoVideo = styled.div`
  border-radius: 0.5rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.7);
  background-color: ${colors.accentDark};
`;

export default ({ onClose, src, title = "" }) => (
  <Modal
    closeButtonAttachment="outside"
    onClose={onClose}
    onClickOutside={onClose}
  >
    <StyledVimeoVideo>
      <iframe
        title={title}
        src={src}
        width="640"
        height="361"
        frameborder="0"
        allow="autoplay; fullscreen"
        allowfullscreen
        autoplay
      ></iframe>
    </StyledVimeoVideo>

    {/* <StyledVideo controls>
      <source src={src} type="video/webm" />
      Sorry, your browser doesn't support embedded videos.
    </StyledVideo> */}
  </Modal>
);
