import React from "react";
import styled from "styled-components";
import { parseToRgb } from "polished";
import { Button, variants } from "@headstorm/foundry-react-ui";

import HeadingText from "../components/typography/HeadingText";
import BodyText from "../components/typography/BodyText";
import fonts from "../constants/fonts";
import colors from "../constants/colors";
import Logo from "./Logo";
import NavItem from "./NavItem";

const StyledLogo = styled(Logo)`
  position: relative;

  fill: ${colors.background};
  width: 18rem;
  height: 7rem;
`;

const Container = styled.header`
  ${({ colorObject }) => `
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 8rem;
    padding: 0 4rem 0 2rem;

    z-index: 10;
    display: grid;
    grid-template-columns: 1fr auto;
    column-gap: 2rem;
    align-items: center;

    backdrop-filter: blur(.25rem);

    background-color: rgba(${colorObject.red},${colorObject.green},${colorObject.blue}, .9);
  `}
`;

const LinksContainer = styled.div`
  width: 100%;
  height: 8rem;
  padding: 0 4rem 0 2rem;

  z-index: 10;
  display: flex;

  gap: max(4vw, 1rem);
  align-items: center;
  margin: auto;
`;

const ButtonContainer = styled(Button.Container)`
  ${fonts.button}
  text-align: center;
  margin: auto;
  color: ${colors.background} !important;
`;

// once scrolled a bit, condense the navbar

export default () => (
  <Container colorObject={parseToRgb(colors.grayXDark)}>
    <StyledLogo>
      <HeadingText style={{ fontSize: "2rem" }}>Foundry UI</HeadingText>
      <BodyText style={{ fontSize: "1rem", color: colors.grayXlight }}>
        Logo coming soon
      </BodyText>
    </StyledLogo>
    <LinksContainer>
      <NavItem name="Contribute" link="#contribute" />
      <NavItem
        name="Read the docs"
        link="https://headstorm.github.io/foundry-ui/"
      />
      <NavItem name="Contact Us" link="mailto: support@foundry-ui.com" />
      <Button
        variant={variants.fill}
        color={colors.primaryDark}
        StyledContainer={ButtonContainer}
        containerProps={{
          as: "a",
          href: "https://headstorm.github.io/foundry-ui",
        }}
      >
        Try it
      </Button>
    </LinksContainer>
  </Container>
);
