import React, { useState } from "react";
import styled from "styled-components";
import { mdiTelevisionPlay } from "@mdi/js";
import { Card, Text } from "@headstorm/foundry-react-ui";

import VideoPlayerModal from "./VideoPlayerModal";
import colors from "../constants/colors";
import fonts from "../constants/fonts";

const ClickableContainer = styled(Card.Container)`
  ${({ thumbnail }) => `
    cursor: pointer;
    height: 18rem;
    background-image: url(${thumbnail});
    background-size: cover;
    background-position: center center;

    justify-content: space-between;
  `}
`;

const GradientHeader = styled(Card.Header)`
  padding-bottom: 2rem;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.3),
    rgba(0, 0, 0, 0)
  );
`;

const GradientFooter = styled(Card.Footer)`
  justify-content: flex-start;
  padding-top: 2rem;
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.3),
    rgba(0, 0, 0, 0)
  );
`;

const HeadingText = styled(Text.Container)`
  ${fonts.heading}
  text-shadow: 0px 2px .5rem rgba(0,0,0,.5);
`;

const ActionText = styled(Text.Container)`
  ${fonts.button}
  text-shadow: 0px 2px .5rem rgba(0,0,0,.5);
  align-items: center;
`;

const TextIcon = styled(Text.IconContainer)`
  vertical-align: middle; /* this should be the default in Foundry UI */
`;

export default ({ videoSrc, videoTitle, children, thumbnail }) => {
  const [videoVisibility, setVideoVisibility] = useState(false);

  return (
    <>
      <Card
        elevation={1.1}
        StyledContainer={ClickableContainer}
        StyledHeader={GradientHeader}
        StyledFooter={GradientFooter}
        containerProps={{
          thumbnail,
          onClick: () => setVideoVisibility(true),
        }}
        header={
          videoTitle && (
            <Text StyledContainer={HeadingText} color={colors.background}>
              {videoTitle}
            </Text>
          )
        }
        footer={
          <Text
            StyledContainer={ActionText}
            StyledIconContainer={TextIcon}
            color={colors.background}
            iconPrefix={mdiTelevisionPlay}
          >
            {children || "Watch the video"}
          </Text>
        }
      />
      {videoVisibility && (
        <VideoPlayerModal
          title={videoTitle}
          src={videoSrc}
          onClose={() => setVideoVisibility(false)}
        />
      )}
    </>
  );
};
