import React from "react";
import styled from "styled-components";
import { mdiDownload } from "@mdi/js";

import HeadingText from "../components/typography/HeadingText";

import Section from "../components/Section";
import Grid from "../components/Grid";
import { Button } from "@headstorm/foundry-react-ui";
import fonts from "../constants/fonts";
import colors from "../constants/colors";
import ExampleForm from "../components/ExampleForm";
import BodyText from "../components/typography/BodyText";

const ButtonContainer = styled(Button.Container)`
  ${fonts.button}
`;

const LeftButtonContainer = styled(ButtonContainer)`
  margin-right: 1rem;
  color: ${colors.grayDark} !important;
`;

const FormContainer = styled.div`
  height: 100%;
`;

export default () => {
  return (
    <Section>
      <Grid side="left">
        <FormContainer>
          <ExampleForm />
        </FormContainer>
        <div>
          <HeadingText
            as="h1"
            internalLinkId="portfolio"
            size="3rem"
            style={{ marginBottom: "2rem" }}
          >
            Everything a growing app needs
          </HeadingText>
          <BodyText>
            Ready to start building? We have the essential components you need
            to bring your application to life, and we're adding more all the
            time.
            <br />
            <br />
            Don't see what you need? Let us know!
          </BodyText>
          <br />
          <br />
          <Button
            StyledContainer={LeftButtonContainer}
            iconPrefix={mdiDownload}
            color={colors.primary}
            elevation={1.2}
            containerProps={{
              as: "a",
              href: "https://headstorm.github.io/foundry-ui/",
            }}
          >
            Get your starter kit here
          </Button>
        </div>
      </Grid>
    </Section>
  );
};
