import React from "react";
import Icon from "./Icon";

export default ({ size = "1rem" }) => (
  <Icon
    size={"24px"}
    viewBoxWidth={38}
    viewBoxHeight={35}
    paths={[
      "M36.8311 33.8516C36.0518 34.6172 35.1117 35 34.0107 35C32.9098 35 31.9635 34.6111 31.1719 33.8333L22.5254 25.3385C21.61 24.4392 21.2389 23.3455 21.4121 22.0573L11.0586 11.8854L7.34766 15.6406C6.90234 16.1024 6.35189 16.3333 5.69629 16.3333C5.04069 16.3333 4.47786 16.1024 4.00781 15.6406L0.705078 12.2865C0.235026 11.8003 0 11.2352 0 10.5911C0 9.94705 0.235026 9.38194 0.705078 8.89583L9.5 0H19.4824C19.9525 0.461806 20.1875 1.02083 20.1875 1.67708C20.1875 2.33333 19.9525 2.89236 19.4824 3.35417L14.3984 8.49479L24.8262 18.7031C26.1374 18.533 27.2507 18.8976 28.166 19.7969L36.8125 28.2917C37.6042 29.0694 38 29.9991 38 31.0807C38 32.1623 37.6104 33.0859 36.8311 33.8516Z ",
    ]}
    pathFills={["#EFFB96"]}
  />
);
