import styled from "styled-components";

export default styled.div`
  width: 100%;
  height: 90vh;
  position: relative;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  @media (max-width: 1080px) {
    height: auto;
    margin-bottom: 8rem;
  }
`;
